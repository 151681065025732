export default {
    // api请求地址
    baseUrl: "https://api.wanshunfengan.cn",

    // 图片域名
    imgDomain: "https://img.wanshunfengan.cn",

    // H5域名
    h5Domain: "https://m.wanshunfengan.cn",

    // 腾讯地图key
    mpKey: "CEYBZ-K75WG-5YSQG-QDIWC-IXQGF-EPBHR",

    // 客服
    webSocket: "wss://api.wanshunfengan.cn/wss",

    // api安全
    apiSecurity: true,

    //本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
    pingInterval: 1500,

    // 公钥
    publicKey: `MIIJQgIBADANBgkqhkiG9w0BAQEFAASCCSwwggkoAgEAAoICAQC5DhbY+moSb/nr
TJLgZMg3FA/ovhe5MBePoBjASMa52fIpD7/TGiNDAiIZdPX3+ZnyWi3/mfll4xCS
ZDigcb9FeSYc7qR60UJzH51IYeJJTAwzOosd674vBFmPf5szEqNoESoMyhVgGd6a
aajydfp7DvRBgp9+i2mkLxltpz/WE9Yj/KQlA1ML2LWDKpAlhhA3e/vgWg2Ujaee
eL/8XtHsOnbFOMvPJz9uGF5sVIHmWDqbJ9z/N7AEzVi9YOV6x2KE9FjGmagRwTdZ
4MZUeqkIlYMKWl2ZIOWvMvs21Gl2St7AAa8C0iEiBo+5x6NDk6LRFvHFU0JYalAs
THpqaVmWkGrpp3C6AH/zEZVW7ywuS+FHYHClMLdsLDCFpK3/FinUU3VXtdH02w8p
OMKtl7VnSihFurqFrINiWNxt5OQdgfvsr9PZAxWWArJBws3xpptdHkAxF8LN6UxS
ehSmlwq1CXaxxLBOUjNA9HZHdTndWweaCn3a+kjuQApu+/uYW7xzIUoz6LMtcF9+
AzPrf55wfvPI+xYK/ksPqn1lTswUQ54E/YNWpsdN01EJMm0tdX1+LMKrP2RdNT5L
BYOChn4mXZo9DUgBc+gj2gJ+rMMwaYNuBUGryIRSxc2tBqHEFLe9e/egBp9sPYpA
mFIqZc6M1az1rELnNLNHmdv3g0GJEQIDAQABAoICAF20HauW+VkGrFUSPBk1zw/c
r8WZY+fYy0VlusK61y3Kh011dRuO06YdkWi0icwb7nECauJPdDK8TGrkJqxPjJvA
IaVqzYyHI4+pxXmeXvfgcxkJnuHyuiWSnUsFQXAWEvoiHtnFBhfYYZhswiirxr7D
LrlxTBPGwiJp2wVkN/XNq5QjXHaFfWq1PAwD5GOrw9BkjoDm+JNtOyAKYqSG2uM0
I2nwPXtSk1LLMhzGmdQqftqLz+LHrvBvjmyugOSvy1opZD6EFc+Pt9hh8etbBPac
xnLamSRtvP/+36e+3PwSI0K0V4slHbPeAK8AwNH6EuqvJJNXenPMuXYE78OsPrka
0PVivvblIRWm3eVmBZg8J7cx+OaOHM6vcoit9KHvxVPrWqLRntnzFtSOn5g262o8
4VQBq6iGBc+enmQZMOUBY7HhD6/S0nmZxS2Ye1kw5jG8cVZ/ofORBXaN03xwfZ/S
zlpEXguNmdAPdzLCK2H3ZuSaFbS9WNLnCAAbPXGrc6t1j1CBZWVpmcQI6Src0r3p
5sfWQcWpij9+QNbEWnFLVOoJJS4kR4Lfc+bq3hsQJ9capa+EJT+y3Z6eks33eBOZ
/+O6GAeEsHV2tnqjZiz6FNH+MOCHa0OSVQX6hpZ/F3ssG0dnpy/Jl60YeTxYUnZs
bxd4vbmQINwQRZe8HZ4BAoIBAQDoy9sF2+0cfBdPKtdETlIcDzLxRP1/tzmcwdiA
vnkF8pWHnG4vE2Q2pUrjctV0oxxQVUSOKdWe96psQYqjJ6yQpb8jLvPwwsQdi/Z7
uEa0V4GeYA9+rPUSefr8lyxDo76PbQfsIQXhrUZT8g2Bg1TEKAGkv7MuTsMmcMtD
YKa0l0MR5Lj3L7BGyUvwf6VGT7NddIgSROOMwoE5lajZnAgNFId3VNdoyvfLuNPH
wSawyXe8E2EoWMIGGTkdz9rTbyjveRY/OyExuaboWnAUngUevHgUlLfppXuJoJNF
B4kHi0QwBELl+aMtWG8dTu1XtjO67HrARPKJsAypJxtLhILxAoIBAQDLgAtDJb9l
ySGFPq/eAUZMoFXK3rlDuXlePnvjaRwsYPd+xOhaDs4YVKwYEwqL0cjRRAFERCUH
Ggr5L+BxvBW1/CJbl5C6FbA0aoBW5kINfmGR04Da0M9ZyZ7AwEYvUGdXe+GZfCTy
2qdy8DHDptN9SzXM/yhh9mrSTB/5VPnG4xmKTYbZTowFMGph59iqP5cCZynpqS/P
piALjUMp9LgysZgjXAJlyV3GbvEdpOwNN/oMFXLyR9k8whVp4rFg+k6rUNYBUFsy
PHsNeYtruj7gdbpuhTvT2yNRH6rlqZ7eoCnfhbNd1UV7TfJ3QNLJLzsQ0c6phCDz
C7bqjZggJyghAoIBAH648aoX1NPrrNiYjoQNSl8h2u5BSukp+T+eudcjWEfnjIQ8
oDIaETQ5+DEmApIydCg6sk3c8f9N/ERfzTMRVruKrihd18aYjx8yYGNqpXpoJBPt
YKHpTqLXKcw0EpFjYkk65dQhCc2WgDEUE0wMDKmWyYZAIC62Cz8yqUMtKrIloQnt
gs5z9DYkP6VUJsjGe/IEjEm9nEy0WJnbIQYMDMnqAe/wjC3sRsVsAES9nO0hTn9f
4USQRFYDhvb2fb0YxqTNOlIDQweJcxUKnfZrKohXudoiNpcGUOBTO7eY6Xvk0KJ+
v1fm82+ssxe2LjbP5Qr8zbk6Dvjqj4nI0u/h21ECggEANslmPtGu0cxwhdr2piJZ
ts+DyXZlEmKg1kqkN/lYaGpCt5Jzq6tIuSwymNingjUch5pzbNmbzmmogqXtGzGz
9qbG6TnI8RBdu/7gRoT/S3q28ferPT3yVQ3++43IV3yiFd94QUY2/Fr5sewQucf0
MEtLVMFtMdKRt8Re51WFWZJSkfWFVIPdFpiOS4WLw2oTUcZMdmfiQ1lKT1HcvzLv
VFaTyxE+ZADuijncGEsKchSb8RzlMW0+gykNctBhSOaav2yn8PqdUs0No/cRHhOq
UevgIVklXkglHfYUoHgmGXGtVEw+mgiNCEFYAdsl5Jwensydgu3boROKT2OHjUB9
gQKCAQEAgmGoPR6Ps1IWT9tfe19x5hFCdu6PLu+kgaVmjp4H1Xjq0dGAZ5ZKToiA
IDJrsqF5H6oTETpG/7sUpiJg/298U9PTfAqDylvPmo6H1vwaE9fKtwyJMx4NGHTG
MHNR4Zg5gfZIPh7DYfgq6xcrS/LZW0MODrYhff1L7mS5ypwh6KnNldpdZ3vXd8QY
+F6PNQ+IEC2vSBuNCIjUuIZlH8QOi5AXsxcOzXGf+E237XRkTXoPcfmqngcK9Odn
F1CR7tBkLcR13BUcF6AxOGTj75aVDVxzPurOOqtQDTjexeWE+63g/nJEP52wFsg/
zK1P4yDuirVEW4/vHmhrBMiGbKxCnA==`
}
